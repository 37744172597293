import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { mq } from '@styles/vars/media-queries.style'

export const SearchItemWrapper = styled.li`
  ${clamp('padding-bottom', 36, 48)};
  ${clamp('padding-top', 36, 48)};
  position: relative;
  list-style-type: none;
`

export const SearchItemContent = styled.div`
  padding-bottom: 3.6rem;

  ${mq.tabletP} {
    padding-bottom: 0;
    padding-top: 0;
  }
`

export const SearchItemExcerpt = styled.div`
  ${clamp('margin-top', 18, 41)};
`

export const SearchItemButton = styled.div`
  ${mq.tabletP} {
    text-align: right;
  }
`
