import React from 'react'
import PropTypes from 'prop-types'
import Link from '@components/Link'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import Spacer from '@components/Spacer'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import RichText from '@components/RichText'
import { Heading2, TextBodySmall, TextBody } from '@components/TextStyles'
import {
  SearchItemWrapper,
  SearchItemContent,
  SearchItemButton,
  SearchItemExcerpt,
} from './index.style'
import HorizontalRule from '@components/HorizontalRule'

const Item = ({ data }) => {
  return (
    <>
      <SearchItemWrapper>
        <Grid>
          <GridItem tabletP={6}>
            <SearchItemContent>
              <AnimateFadeIn>
                <Heading2>{data.title}</Heading2>
              </AnimateFadeIn>
              <Spacer size={[16, 21]} />
              <AnimateFadeIn>
                <TextBody>{data.createdAt}</TextBody>
              </AnimateFadeIn>

              <AnimateFadeIn>
                {data.excerpt && (
                  <SearchItemExcerpt>
                    <TextBodySmall maxWidth={22}>{data.excerpt}</TextBodySmall>
                  </SearchItemExcerpt>
                )}

                {data.intro && (
                  <SearchItemExcerpt>
                    <RichText maxWidth={22} content={data.intro.content} />
                  </SearchItemExcerpt>
                )}
              </AnimateFadeIn>
            </SearchItemContent>
          </GridItem>
          <GridItem tabletP={6}>
            <AnimateFadeIn>
              <SearchItemButton>
                <Link page={data}>Read More</Link>
              </SearchItemButton>
            </AnimateFadeIn>
          </GridItem>
        </Grid>
      </SearchItemWrapper>
      <HorizontalRule />
    </>
  )
}

Item.propTypes = {
  data: PropTypes.object,
}

export default Item
