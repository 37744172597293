import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
import Spacer from '@components/Spacer'
import Container from '@components/Container'
import { Heading4, TextBody } from '@components/TextStyles'
import { SearchList } from './index.style'
import Item from './Item'

const SearchResults = ({ searchQuery }) => {
  const {
    localSearchContent: { index, store },
  } = useStaticQuery(graphql`
    query {
      localSearchContent {
        index
        store
      }
    }
  `)
  const results = useFlexSearch(searchQuery, index, store)

  return (
    <Container>
      <Heading4>Search results for '{searchQuery}'</Heading4>
      <Spacer size={[24, 72]} />

      {results.length ? (
        <SearchList>
          {React.Children.toArray(
            results.map(result => <Item data={result} />)
          )}
        </SearchList>
      ) : (
        <TextBody>No Results</TextBody>
      )}
    </Container>
  )
}

SearchResults.propTypes = {
  searchQuery: PropTypes.string,
}

export default SearchResults
