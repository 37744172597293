import React from 'react'
import queryString from 'query-string'
import Seo from '@components/Seo'
import Spacer from '@components/Spacer'
import Footer from '@components/Footer'
import SearchResults from '@components/SearchResults'

const Search = ({ location }) => {
  const { s } = queryString.parse(location.search)

  return (
    <>
      <Seo
        data={{
          title: 'Search Results',
          description: 'Search Loyal To You Always',
        }}
        slug={`search`}
      />

      <Spacer size={[170, 300]} />
      <SearchResults searchQuery={s} />
      <Spacer size={[120, 160]} />
      <Footer location={location} />
    </>
  )
}

export default Search
